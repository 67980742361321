// 重复项目列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      color="indigo"
      icon="mdi-clipboard-text"
      title="存疑项目列表"
      class="px-5 py-3 mt-12"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th>存疑原因</th>
            <th>地市</th>
            <th>产业类别</th>
            <th class="text-right">
              标签
            </th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ project.report.name }}</td>
            <td>{{ project.reason }}</td>
            <td>{{ project.report.data.city }}</td>
            <td>{{ project.report.data.industry }}</td>
            <td>{{ project.report.data.tags }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="error"
                @click="deleteProject(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                :to="`report-details/${project.formId}/${project.reportId}`"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      projectList: [],
    }),
    created () {
      this.getProjectList()
    },
    methods: {
      getProjectList () { // 获取项目列表
        this.$axios.get('/audit-doubts', {
          params: {
            from: 'org',
          },
        })
          .then((res) => {
            console.log(res.data.data)
            this.projectList = res.data.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      deleteProject (index) { // 删除项目
        this.$swal({
          title: '你确定要删除此存疑吗？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.$axios.delete(`/audit-doubts/${this.projectList[index].id}`)
              .then((response) => {
                this.projectList.splice(index, 1)
                this.$swal({
                  title: '删除成功!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
      },
      seeProject (index) { // 查看项目
        console.log(this.projectList[index].id)
        console.log(this.projectList[index].id)
        // this.$router.push({
        //   name: 'Details',
        //   params: {
        //     formId: this.projectList[index].formId,
        //     id: this.projectList[index].id,
        //   },
        // })
      },
    },
  }
</script>
